/* GLOBAL STYLES */
body {
	/* font-family: 'Times New Roman', Times, serif; */
	background-color: rgba(203, 172, 172, 0.434);
}
a:link {
	text-decoration: none;
	color: black;
}
a:hover {
	text-emphasis: none;
}
/* ::-webkit-scrollbar {
	display: none;
} */
:root {
	--gray-900: #1a202c;
	--gray-800: #2d3748;
	--gray-700: #4a5568;
	--gray-600: #718096;
	--gray-500: #a0aec0;
	--gray-400: #cbd5e0;
	--gray-300: #e2e8f0;
	--gray-200: #edf2f7;
	--gray-100: #f7fafc;
	--red-100: #fff5f5;
	--red-200: #fed7d7;
	--red-300: #feb2b2;
	--red-400: #fc8181;
	--red-500: #f56565;
	--red-600: #e53e3e;
	--red-700: #c53030;
	--red-800: #9b2c2c;
	--red-900: #742a2a;
	--blue-100: #ebf8ff;
	--blue-200: #bee3f8;
	--blue-300: #90cdf4;
	--blue-400: #63b3ed;
	--blue-500: #4299e1;
	--blue-600: #3182ce;
	--blue-700: #2b6cb0;
	--blue-800: #2c5282;
	--blue-900: #2a4365;
	--egg-color: bisque;
	--white: white;
	--red: #f56565;
	--pink1: rgb(255, 197, 197);
	--pink2: rgb(246, 196, 196);
	--pink3: rgb(254, 217, 217);
	--text-decoration-color: var(--gray-400);
	--text-color: var(--gray-800);
	--focus-ring-color: var(--blue-500);
}

.customer-list {
	margin-top: 6%;
	margin-bottom: 2%;
	text-align: center;
}
.customer-search input[type='text']:focus {
	border: none;
	border: 0px solid;
	box-shadow: 0 0 8px 0 rgb(255, 197, 197);
	background-color: rgb(254, 248, 248);
}
textarea:focus,
input:focus {
	outline: none;
}
.fa-search:before {
	content: '\f002';
	margin-left: -30px;
	color: #2d84b3;
}
input.customer-search {
	border-radius: 10px;
	border: none;
	box-shadow: 0 0 2px 0 rgb(246, 196, 196);
	background-color: rgb(254, 217, 217);
	height: 2em;
	font-size: medium;
}
.customer-cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.column-headers {
	flex-wrap: wrap;
	justify-content: space-between;
	display: flex;
}
.column-headers-div {
	background-color: rgb(230 156 156 / 53%);
	flex-basis: 100%;
	margin: 0% 5% -0.3%;
	display: flex;
	/* border-radius: 10px; */
	height: 3em;
}

.customer-card {
	background-color: rgba(255, 221, 221, 0.53);
	flex-basis: 100%;
	margin: 0.2% 5%;
	display: flex;
	border-radius: 10px;
	height: 3em;
}

.customer-data.icon {
	flex-basis: 4%;
	margin-top: 0;
	padding: 0.5em;
	font-size: large;
	background-color: blanchedalmond;
}
.fa-building:before {
	color: #2d84b3;
}
.column-names.icon {
	flex-basis: 4%;
}

.column-names {
	flex-basis: 30%;
	width: 30%;
	margin-top: 0.6em;
	font-weight: bolder;
}
.contact-data-link {
	width: 35%;
}
.customer-data {
	flex-basis: 30%;
	width: 30%;
	margin-top: 0.6em;
}

.customer-card a {
	color: rgb(87, 87, 87);
	text-decoration: none;
	border-bottom: paleturquoise;
	display: inline-block;
	border-bottom: red;
}

.customer-card a:hover {
	color: rgb(7, 7, 7);
}

.cardIcon {
	display: inline-block;
	margin-right: 7px;
	color: rgb(167, 147, 147);
}

.highlight-text {
	font-weight: bolder;
	background-color: rgb(247, 236, 171);
}

.search-container {
	display: flex;
	justify-content: space-around;
	margin-bottom: 1%;
	align-items: center;
}
.switch {
	margin-left: 5%;
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
	/* box-shadow: 0 0 2px 0 dodgerBlue; */
}
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgb(234, 97, 97);
	-webkit-transition: 0.4s;
	transition: 0.4s;
}
.slider:before {
	position: absolute;
	content: '';
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 2px;
	background-color: rgb(255, 181, 181);
	-webkit-transition: 0.4s;
	transition: 0.4s;
}
input:checked + .slider {
	background-color: rgb(145, 223, 128);
}
input:focus + .slider {
	box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
	border-radius: 10px;
	height: 30px;
}
.slider.round:before {
	border-radius: 50%;
}
.wiggle-button {
	margin-top: 12px;
	position: relative;
	outline: none;
	text-decoration: none;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	text-transform: uppercase;
	height: 45px;
	width: 130px;
	opacity: 1;
	background-color: #fcf0f0;
	border: 1px solid rgba(208, 149, 149, 0.6);
}

.wiggle-button .bn54span {
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	color: #000000;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.7px;
}

.wiggle-button:hover {
	animation: bn54rotate 0.7s ease-in-out both;
	transition: background-color 0.3s ease-in-out;
}

.wiggle-button:hover .bn54span {
	animation: bn54storm 0.7s ease-in-out both;
	animation-delay: 0.06s;
}
.wiggle-button:hover {
	background-color: rgb(254, 217, 217);
}

@keyframes bn54rotate {
	0% {
		transform: rotate(0deg) translate3d(0, 0, 0);
	}
	25% {
		transform: rotate(3deg) translate3d(0, 0, 0);
	}
	50% {
		transform: rotate(-3deg) translate3d(0, 0, 0);
	}
	75% {
		transform: rotate(1deg) translate3d(0, 0, 0);
	}
	100% {
		transform: rotate(0deg) translate3d(0, 0, 0);
	}
}

@keyframes bn54storm {
	0% {
		transform: translate3d(0, 0, 0) translateZ(0);
	}
	25% {
		transform: translate3d(4px, 0, 0) translateZ(0);
	}
	50% {
		transform: translate3d(-3px, 0, 0) translateZ(0);
	}
	75% {
		transform: translate3d(2px, 0, 0) translateZ(0);
	}
	100% {
		transform: translate3d(0, 0, 0) translateZ(0);
	}
}
.customer-page {
	margin-top: 7%;
}
.customer-list .window-overlay {
	display: flex;
	align-items: flex-start;
	z-index: 0;
}
.customer-page .window-overlay {
	display: flex;
	align-items: flex-start;
	z-index: 0;
}

.window-overlay {
	align-items: flex-start;
	background-color: #000000a3;
	display: block;
	height: 100%;
	justify-content: center;
	left: 0;
	overflow-y: auto;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 20;
}
.window {
	background-color: var(--gray-900);
	border-radius: 2%;
	display: block;
	overflow: hidden;
	position: relative;
	width: 45vw;
	/* height: 60vh; */
	margin: auto;
	z-index: 25;
	min-height: 490px;
	color: white;
	margin-top: 7%;
}

.fa-times-circle:before {
	color: wheat;
}

/* Form
  ––––––––––––––––––––––––––––––––– */
.window label {
	font-weight: bold;
	display: flex;
	margin-bottom: -1px;
}

.window input {
	display: flex;
	color: wheat;
	/* font-weight: bold; */
	letter-spacing: 1px;
	width: 100%;
}

/* :not(.exclude_default_style) */
input[type='submit'],
input[type='reset'],
input[type='button'],
button {
	padding: 0.5rem 1.25rem;
	margin-top: 1em;
	font-size: 1rem;
	border-radius: 10px;
	text-decoration: none;
	font-weight: bold;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	display: inline-block;
	line-height: initial;
	transition: background-color 200ms ease-in-out, border 200ms ease-in-out,
		transform 200ms ease-in-out;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	user-select: none;
}

.feedback-input {
	font-family: Helvetica, Arial, sans-serif;
	font-weight: 500;
	font-size: 18px;
	border-radius: 5px;
	line-height: 22px;
	background-color: var(--gray-800);
	border: 2px solid var(--gray-500);
	transition: all 0.3s;
	padding: 10px;
	margin-bottom: 15px;
	outline: 0;
}

.form-error-message {
	display: flex;
	font-size: 0.8em;
	color: var(--red-500);
	margin-top: -7px;
}
.error-name {
	font-style: italic;
	color: var(--gray-300);
}
.error-value {
	font-style: italic;
	color: var(--red-500);
}
.feedback-input:focus {
	border: 2px solid var(--red-300);
}
.valid-input.feedback-input:focus {
	border: 2px solid var(--blue-500);
}

.window button:hover {
	background-color: var(--gray-400);
	border: 2px solid var(--gray-700);
}

.window select {
	color: wheat;
	display: flex;
	width: 32%;
}
.customer-page .window select {
	width: 100%;
}
.window option {
	color: wheat;
}

.side-by-side-input-fields {
	display: flex;
	justify-content: space-evenly;
}
.form-center-input-container {
	display: flex;
	justify-content: space-around;
}
.form-center-input {
	display: flex;
	width: 40%;
	flex-direction: column;
	margin: 1%;
}
.bottom-center-of-form {
	margin-top: 2%;
	display: flex;
	flex-direction: column;

	align-items: center;
}
.window button {
	width: 32%;
	margin-bottom: 3%;
}

.loading-icon {
	animation: spin 2s linear infinite;
}
.centered-input {
	width: 50%;
	margin: auto;
	margin-top: 4%;
}
.centered-button {
	display: flex;
	justify-content: center;
}
.customer-info-container {
	background-color: var(--gray-400);
	margin: 2%;
	border-radius: 10px;
	box-shadow: 5px 0px 15px -2px var(--red-300);
}

.customer-info-card {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 1%;
}
.customer-data-continuer {
	flex-basis: 25%;
	max-width: 335px;
	margin: 1% 0% 1% 0%;
}
.customer-key-data {
	font-weight: bold;
	flex-basis: 10%;
	padding: 10px;
	background-color: var(--red-100);
	margin-top: 1.5%;
	margin-bottom: 1.5%;
	margin: 2%;
	border-radius: 10px;
}
.customer-value-data {
	font-weight: 100;
	font-style: italic;
	flex-basis: 10%;
	padding: 10px;
	background-color: var(--red-200);
	margin-top: 1.5%;
	margin-bottom: 1.5%;
	margin: 2%;
	border-radius: 10px;
}
.fa-pencil-alt:before {
	margin-right: 5px;
}
.customer-info-edit-section {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-around;
	padding-bottom: 0.5%;
}
.customer-info-edit-section a {
	color: black;
}
.edit-button {
	border: 1px solid var(--gray-600);
	border-radius: 5px;
	flex-basis: 8%;
	text-align: center;
	cursor: pointer;
	border-radius: 10px;
	background-color: var(--blue-200);
}
.edit-button:hover {
	background-color: var(--gray-200);
}
.edit-button:active {
	color: var(--red-500);
}
.created-date {
	margin-right: 10px;
	font-weight: bolder;
}
.last-modified-date {
	margin-right: 10px;
	font-weight: bolder;
}
.time {
	font-style: italic;
}
.carriers-invoices {
	display: flex;
	align-items: flex-start;
	height: 50vh;
	margin: 2%;
}
.carriers-container {
	margin-left: 1%;
	min-height: 20em;
	flex-basis: 55%;
}
.invoices-container {
	margin-left: 1%;
	min-height: 20em;
	max-height: 22em;
	flex-basis: 45%;
	/* overflow: scroll; */
	/* contain: strict; */
}
.customer-invoice-list::-webkit-scrollbar {
	display: none;
}

legend {
	color: var(--blue-900);
	width: auto;
	margin-left: 5%;
}
fieldset {
	border: 2px solid var(--gray-400);
	border-radius: 10px;
}

.son-to-be-invoicesComponent {
	background-color: var(--red-100);
	height: 100%;
	width: 100%;
	flex-basis: 35%;
}

.carrier-card {
	flex-basis: 95%;
	margin: 2% 2% 3%;
	box-shadow: 0 4px 8px 0 rgba(233, 71, 71, 0.2);
	border-top: 1px solid black;
	background-color: var(--gray-400);
	transition: 0.3s;
	border-radius: 10px;
	padding: 0 10px 10px;
}
.carrier-card-header {
	display: flex;
	justify-content: center;
	font-weight: 900;
	font-size: 1.7em;
}

.carrier-card-link-list {
	padding: 8px;
	box-shadow: 0 4px 8px 0 rgba(233, 71, 71, 0.2);
}
.carrier-card-links-width {
	text-align: center;
	width: 25.1%;
	margin: 0 8%;
}
.carrier-card_link-headers {
	display: flex;
	justify-content: space-between;
	background-color: var(--blue-300);
	font-size: 1.2em;
	font-weight: 800;
	font-family: monospace;
}
.pop-out-link {
	transition: 0.1s;
}
.pop-out-link:hover {
	transform: scale(1.1);
}
.carrier-card_links {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid var(--gray-600);
	background-color: var(--red-200);
}
.carrier-card_link_terminus {
	color: var(--blue-800);
	font-weight: 900;
}
.carrier-card_info-edit {
	display: flex;
	align-items: center;
	margin-top: 2em;
}

.carrier-card-edit {
	border: 1px solid var(--gray-600);
	border-radius: 10px;
	background-color: var(--blue-200);
}
.carrier-card-edit:hover {
	background-color: var(--gray-200);
}
.card-info-title {
	font-weight: 800;
	font-family: monospace;
	/* border-bottom: 1px solid black; */
}
.card-info-value {
	font-weight: 500;
	font-style: italic;
}

.pop-out-link {
	color: var(--blue-800);
}
.pop-out-link:visited {
	color: var(--blue-800);
}

.carrier-name-id {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}
.carrier-name {
	margin-left: 6px;
	font-size: 1.5em;
	font-weight: bold;
}
.carrier-pickup-number {
	display: inline-block;
	border-left: 1.5px solid var(--blue-900);
	border-bottom: 1.5px solid var(--blue-900);
	height: fit-content;
	text-align: center;
}
p:first-child {
	font-weight: bold;
}
.carrier-pickup-number:last-child {
	font-weight: lighter;
}
.carrier-pickup-number p {
	margin: 0;
}

.customer-invoice-list {
	display: flex;
	flex-wrap: wrap;
	overflow: scroll;
	max-height: 18em;
}
.card-headers {
	position: sticky;
	top: 0;
	margin-left: 3.5%;
	width: 93%;
	display: flex;
	justify-content: space-between;
	background-color: var(--gray-400);
	border-radius: 4px;
	font-weight: bold;
	font-family: monospace;
	margin-bottom: 1%;
}
.customer-invoice-card {
	margin: 1% 4%;
	flex-basis: 100%;
	display: flex;
	justify-content: space-between;
	background-color: var(--red-200);
	font-family: monospace;
	box-shadow: 0 5px 7px -1px rgb(51 51 51 / 23%);
	cursor: pointer;
	border-radius: 4px;
	transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98),
		box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
}
.customer-invoice-card:hover {
	transform: scale(1.1);
	box-shadow: 0 9px 47px 11px rgba(51, 51, 51, 0.18);
}
.invoice-id {
	margin-left: 5%;
	color: var(--blue-700);
	width: 33%;
	text-align: center;
}
.invoice-data {
	width: 33%;
	text-align: center;
}
.add_rate-card-tier {
	margin-left: 20px;
}
.invoice-total {
	margin-right: 5%;
	color: var(--blue-700);
	width: 33%;
	text-align: center;
}
.carrier-rate-card-link {
	color: var(--blue-900);
	margin-left: 10px;
}

carrier-rate-card-link:hover {
	color: var(--gray-600);
}

.international-rate-card {
	display: flex;
}
.domestic-rate-card {
	display: flex;
}
.add_rate-card-tier {
	padding: 3px 7px;
	border: 0.1em solid rgb(255, 255, 255);
	border-radius: 2em;
	box-sizing: border-box;
	text-decoration: none;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	color: #ffffff;
	text-shadow: 0 0.04em 0.04em rgba(255, 255, 255, 0.253);
	text-align: center;
	transition: all 0.2s;
}
.add_rate-card-tier:hover {
	color: black;
	background-color: rgb(255, 255, 255);
	cursor: pointer;
}
.edit-carrier_button {
	display: inline-block;
	padding: 5px 10px;
	margin: 0 0.1em 0.1em 0;
	border: 0.1em solid rgb(255, 255, 255);
	border-radius: 2em;
	box-sizing: border-box;
	text-decoration: none;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	color: #ffffff;
	text-shadow: 0 0.04em 0.04em rgba(255, 255, 255, 0.253);
	text-align: center;
	transition: all 0.2s;
}
.edit-carrier_button:hover {
	color: black;
	background-color: rgb(255, 255, 255);
	cursor: pointer;
}
.error-text {
	color: var(--red-500);
}

.pop-up-header {
	text-align: center;
	font-size: 1.5em;
}
.tier-selection-buttons {
	display: flex;
	justify-content: space-evenly;
}
button.select-tier-button {
	width: 20%;
	background-color: var(--gray-300);
}
button.create-tier-button {
	width: 20%;
	background-color: var(--gray-300);
}
button.active_button {
	background-color: var(--gray-800);
	border: 2px solid var(--blue-200);
	color: var(--gray-100);
}
button.active_button:hover {
	background-color: var(--gray-800);
	border: 2px solid var(--blue-200);
	cursor: initial;
}
.tier-form {
	width: 60%;
	margin: auto;
}
button.tier_submit-button {
	width: 50%;
	margin-left: 25%;
}
.add-carrier-service {
	border: 1px solid var(--gray-600);
	border-radius: 5px;
	text-align: center;
	cursor: pointer;
	border-radius: 10px;
	padding: 5px 8px 5px 8px;
}

.add-carrier-service:hover {
	background-color: var(--gray-200);
}
.add-carrier-service:visited {
	color: var(--gray-900);
}

.add-carrier-service:active {
	color: var(--red-300);
}
.center-item {
	margin-top: 15px;
	display: flex;
	justify-content: space-evenly;
	margin-bottom: 2%;
}

.edit-carrier-service {
	text-align: center;
}
.window .edit-carrier-service label {
	width: 50%;
	margin: 10px auto 0px;
}
.window .edit-carrier-service input {
	width: 50%;
	margin: auto;
	background-color: var(--gray-800);
}
.edit-carrier-service .check-box-container {
	font-size: medium;
}
.add-carrier .check-box-container {
	font-size: medium;
}
.add-carrier {
	text-align: center;
}
.window .add-carrier label {
	width: 50%;
	margin: 10px auto 0px;
}
.window .add-carrier input {
	width: 50%;
	margin: auto;
	background-color: var(--gray-800);
}
.window .add-carrier select {
	width: 50%;
	margin: auto;
	background-color: var(--gray-800);
}
.add-carrier-label {
	display: flex;
	width: 50%;
	margin: 10px auto 0px;
}
.add-carrier button {
	margin-left: 0px;
}
.add-carrier .fa-spinner {
	margin: 10% auto;
}
