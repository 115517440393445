.osm-page_wrapper {
	text-align: center;
}
.osm-invoice-list {
	margin-top: 2%;
	margin-bottom: 2%;
	text-align: center;
}
.invoice-cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.center-div {
	margin: 0.6% auto;
}
.centerd-inputs {
	max-width: 70%;
	margin: auto;
	display: flex;
	flex-direction: column;
}
.osm-file-upload-warper {
	height: 9vh;
}
.osm-upload-file {
	margin-bottom: 2%;
}

.osm-file-upload {
	max-width: 99%;
}
.osm-side-by-side {
	justify-content: space-between;
	width: 70%;
	margin: auto;
}

.osm-invoice_page {
	text-align: center;
}

input[type='date']::-webkit-calendar-picker-indicator {
	cursor: pointer;
	border-radius: 4px;
	margin-right: 2px;
	opacity: 0.6;
	filter: invert(0.8);
}
input[type='date']::-webkit-calendar-picker-indicator:hover {
	opacity: 1;
}

.osm-download-button:hover {
	cursor: pointer;
}

.osm-page-link-container {
	display: flex;
	justify-content: space-evenly;
}
.carrier-menu-buttons {
	margin: 0px 1em;
	border: 1px solid var(--gray-600);
	border-radius: 5px;
	text-align: center;
	cursor: pointer;
	border-radius: 10px;
	padding: 5px 8px 5px 8px;
}
.carrier-menu-buttons:hover {
	background-color: var(--gray-200);
}
.carrier-menu-buttons:visited {
	color: var(--gray-900);
}
