.error-table {
	width: 92vw;
	overflow-y: hidden;
	margin-left: 1%;
	border-radius: 8px 8px 0px 0px;
	margin-top: 6%;
	margin-bottom: 2%;
}
.error-page-warper {
	margin-top: 8%;
}
.invoice-table {
	width: 92vw;
	margin: 1% 1%;
	border-radius: 8px 8px 0px 0px;
	overflow: hidden;
}
.unprocessed-shipments-table.error-table {
	width: 96vw;
	overflow-y: hidden;
	margin-left: 2%;
	border-radius: 8px 8px 0px 0px;
	margin-top: 1%;
	margin-bottom: 2%;
}

.table-title {
	text-align: center;
	font-weight: bolder;
	font-size: smaller;
}
.table-title,
table {
	font-size: smaller;
}
table {
	font-family: Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	width: 100%;
}

table,
table th {
	border: 1px solid #ddd;
	/* padding: 5px 6px; */
}

table tr:nth-child(even) {
	background-color: #f2f2f2;
}

table tr:hover {
	background-color: #ddd;
}

.error-table th {
	white-space: nowrap;
}
table th {
	text-align: center;
	background-color: #04aa6d;
	color: white;
}
table td {
	text-align: center;
	border: 1px solid rgb(183, 177, 177);
	/* padding: 0px 4px; */
}

.editable-table-input {
	background: transparent;
	border: 1px solid rgba(134, 134, 134, 0.236);
	border-radius: 8px;
	padding: 8px;
	box-sizing: border-box;
	width: 100%;
	text-align: center;
}
.editable-table-input:focus {
	border: 1px solid rgb(0, 96, 164);
	box-sizing: border-box;
}
.invoice-table .editable-table-input {
	padding: 2px 0px;
	/* width: inherit; */
}
.highlight-cell {
	background: rgb(254, 181, 181);
}
.error-table_submit-button {
	margin-top: 1%;
	display: flex;
	justify-content: space-evenly;
}
.table-header-checkbox-tooltip .error-table-header-text {
	background-color: #04aa6d;
	font-weight: bold;
	color: white;
	text-align: center;
	border-radius: 6px;
	padding: 12px 7px;
	position: absolute;
	min-width: 4%;
	left: 94%;
	margin-left: 10px;
}
.invoice-table .table-header-checkbox-tooltip .error-table-header-text {
	font-size: small;
}

.error-table-tooltip .error-table-tooltip-button {
	background-color: rgb(254, 181, 181);
	border-color: rgba(255, 52, 52, 0.593);
	color: rgba(255, 0, 0, 0.593);
	font-weight: bold;
	text-align: center;
	border-radius: 6px;
	padding: 7px 7px;
	position: absolute;
	min-width: 4%;
	left: 93.5%;
	margin-left: 10px;
	margin-top: 1px;
	padding: 0px;
}
.error-table-tooltip-button:hover {
	cursor: pointer;
}
.error-table-tooltip .error-table-tooltip-button:hover {
	background-color: rgb(238, 141, 141);
}

.error-table::-webkit-scrollbar {
	width: 20px;
}

/* Track */
.error-table::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 0px 0px 8px 8px;
}

/* Handle */
.error-table::-webkit-scrollbar-thumb {
	background: rgb(4 170 109);
	border-radius: 0px 0px 8px 8px;
}

/* Handle on hover */
.error-table::-webkit-scrollbar-thumb:hover {
	background: rgb(254, 181, 181);
}

.error-list-container {
	flex-basis: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
}
.error-list {
	cursor: pointer;
	font-size: large;
	flex-basis: 30%;
	text-align: center;
}
.error-list:hover {
	color: #04aa6d;
}
.error-list::first-letter {
	color: #04aa6d;
}
.hide-element {
	visibility: hidden;
}
.remove-element {
	display: none;
}
.error-list_section {
	min-height: 4vh;
	margin-top: 2%;
	display: flex;
	justify-content: center;
}
.info_details_numbers {
	font-weight: bolder;
	font-size: x-large;
}
.info_details_text {
	font-weight: 400;
}
.display-error-list_button {
	border: 1px solid var(--gray-600);
	border-radius: 5px;
	text-align: center;
	cursor: pointer;
	border-radius: 10px;
	padding: 5px 8px 5px 8px;
	transition: 0.1s;
}
.fa-arrow-right:before {
	margin-left: 90%;
}
.display-error-list_button:hover {
	background-color: var(--gray-200);
	color: var(--gray-900);
	transform: scale(1.1);
}

.paginate-buttons {
	display: flex;
	justify-content: space-between;
	flex-basis: 5%;
	align-items: flex-end;
}
.paginate-page-number {
	margin: 1% 1%;
	border: 1px solid var(--gray-600);
	border-radius: 5px;
	text-align: center;
	min-width: 70px;
	border-radius: 10px;
	padding: 2px 8px 2px 8px;
}
.paginate-table-refresh {
	cursor: pointer;
}
.paginate-button {
	margin: 1% 1%;
	border: 1px solid var(--gray-600);
	border-radius: 5px;
	text-align: center;
	cursor: pointer;
	border-radius: 10px;
	padding: 5px 8px 5px 8px;
	transition: 0.1s;
}
.paginate-button:hover {
	border: 1px solid var(--gray-700);
	background-color: #dbf0fb;
}

/* UNPROCESSED SHIPMENTS PAGE */
.unprocessed-page {
	margin-top: 8vh;
}
.info-section_unprocessed-page {
	display: flex;
	margin-top: 1%;
}
.info_details_unprocessed-page {
	flex-basis: 33%;
	text-align: center;
}
.buttons_unprocessed-page {
	display: flex;
}
.process-shipments {
	flex-basis: 50%;
	text-align: center;
	background-color: #e6f0f5;
	margin: 1% 4%;
	cursor: pointer;
	padding: 9px 0px;
	border-radius: 8px;
}
.unprocessed-shipments {
	flex-basis: 50%;
	text-align: center;
	background-color: #e6f0f5;
	margin: 1% 5%;
	padding: 9px 0px;
	color: black;
	border-radius: 8px;
}
.unprocessed-shipments:hover {
	background-color: #dbf0fb;
	color: black;
}
.process-shipments:hover {
	background-color: #dbf0fb;
}
.table-title-refresh {
	flex-basis: 35%;
	display: flex;
	justify-content: center;
}
.pagination-effects {
	flex-basis: 25%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.refresh-tooltip .refresh-tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: #65a6c8;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 12px;
	/* Position the tooltip */
	position: relative;
	bottom: 100%;
	margin-left: -155px;
}
.refresh-tooltip:hover .refresh-tooltiptext {
	visibility: visible;
}
.refresh-tooltip .refresh-tooltiptext::after {
	content: ' ';
	position: absolute;
	top: 100%; /* At the bottom of the tooltip */
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #65a6c8 transparent transparent transparent;
}

.disputes-download {
	display: flex;
	margin-left: 4%;
	justify-content: center;
}
.disputes-download span {
	background-color: #04aa6db4;
	padding: 5px 10px;
	border-radius: 10px;
	cursor: pointer;
	transition: 0.5s;
	margin-right: 5%;
}
.disputes-download span:hover {
	background-color: rgb(92, 200, 162);
	border-radius: 12px 2px 12px 2px;
}
.add-dispute-note {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0% 10%;
}
.add-dispute-note textarea {
	color: wheat;
	width: 100%;
}

.disputes-tooltip-container {
}

.disputes-tooltip-container .disputes-tooltip-text {
	visibility: hidden;
	background-color: rgb(51, 124, 84);
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 10px;
	position: absolute;
	left: 50%;
	margin-top: -2%;
}

.disputes-tooltip-container .disputes-tooltip-text::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: rgb(51, 124, 84) transparent transparent transparent;
}

.disputes-tooltip-container:hover .disputes-tooltip-text {
	visibility: visible;
}

.demo_right-click {
	font-size: 14px;
	background-color: #fff;
	border-radius: 2px;
	padding: 5px 0 5px 0;
	width: 150px;
	height: auto;
	margin: 0;
	/* use absolute positioning  */
	position: absolute;
	list-style: none;
	box-shadow: 0 0 20px 0 #ccc;
	opacity: 1;
	transition: opacity 0.5s linear;
}
.side-by-side-checkbox {
	display: flex;
	width: 100%;
	justify-content: space-around;
}
.create-invoice-container {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}
.deactivate-button.unprocessed-shipments {
	pointer-events: none;
	background-color: #e8ecee;
	color: rgba(0, 0, 0, 0.288);
}
.table-cell-note {
	cursor: pointer;
	height: 1.5em;
	max-width: 4em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.error-table-tooltip .fa-file-arrow-down {
	color: #1c1e1f69;
	margin-left: 5px;
	cursor: pointer;
}

.invoice-billing-period-start {
	background-color: #7fc0e338;
}
.invoice-billing-period-end {
	background-color: #e37f7f38;
}
table tr.highlight-row {
	background-color: rgba(238, 141, 141, 0.432);
}
/* .disputes-tooltip-container .error-table-tooltip-button {
	margin-top: -9px;
} */
.error-table .error-table-tooltip-button {
	margin-top: 4px;
}
/* .disputes-tooltip-container table td {
	padding: 10px 4px;
} */
.dispute-table.error-table {
	margin-top: 100px;
}
.editable-table-input {
	background: transparent;
	border: 1px solid rgba(134, 134, 134, 0.236);
	border-radius: 8px;
	padding: 8px;
	box-sizing: border-box;
	width: 100%;
	text-align: center;
}
.editable-table-input:focus {
	border: 1px solid rgb(0, 96, 164);
	box-sizing: border-box;
}

.dead-input.editable-table-input {
	border: none;
	pointer-events: none;
}
.dead-input.editable-table-input:focus {
	border: none;
}
th {
	background: white;
	position: sticky;
	transform-origin: top;
	transition: all 0.2s ease-in-out;
}

/* new errors page */
.shipment-error-page_dhl {
	margin-top: 8vh;
	display: flex;
	text-align: center;
}
.shipments-list {
	flex-basis: 30%;
	background-color: lightgray;
	border-right: 1px solid rgb(200, 200, 200);
	max-height: 87.6vh;
	overflow: auto;
	overflow-x: hidden;
}
.shipment-data {
	flex-basis: 80%;
	overflow: auto;
	width: fit-content;
	max-height: 87.6vh;
}
/* scroll bar */
.shipments-list::-webkit-scrollbar {
	width: 8px;
}

/* Track */
.shipments-list::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.shipments-list::-webkit-scrollbar-thumb {
	background: rgb(149, 146, 146);
}
/* scroll bar */
.shipment-data::-webkit-scrollbar {
	width: 8px;
}

/* Track */
.shipment-data::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.shipment-data::-webkit-scrollbar-thumb {
	background: rgb(149, 146, 146);
}
.shipment-list-container {
	margin-top: 24px;
}
.search-container {
	margin-top: -24px;
	display: flex;
}
.shipments-details-header {
	/* background-color: #466d82; */
	min-height: 45px;
	display: flex;
	justify-content: space-evenly;
}
.shipments-list-header {
	font-weight: bold;
	position: fixed;
	background-color: rgb(197 189 203);
	/* height: 24px; */
}
.shipments-disputes {
	background-color: rgb(197 189 203);
	font-weight: bold;
	margin-top: 5%;
}
.shipments-details-header {
}
.shipment-card {
	cursor: pointer;
	border-top: 1px solid black;
	padding: 5px;
}
.error-section-header {
	font-weight: 600;
	border-bottom: 1px solid black;
}
.error-shipment-cell {
	flex-basis: 33.3%;
	border-left: 1px solid black;
}
.error-shipment-header-warper {
	position: relative;
	overflow: hidden;
	height: 1.2em;
	width: 100%;
	border-bottom: 1px solid black;
}
.error-shipment-headers {
	display: flex;
	position: fixed;
}

.error-shipment-header-name {
	font-weight: bold;
	flex-basis: 33.3%;
	border-left: 1px solid black;
	background-color: #c5bdcb;
}
.shipment-data-title {
	font-weight: 500;
}
.shipment-title-dates-warper {
	display: flex;
	justify-content: space-evenly;
	background-color: #c5bdcbbd;
	border-radius: 6px;
	/* box-shadow: inset 0px 1px 5px 0px; */
}
.shipment-title1-warper {
	background-color: #c5bdcbbd;
	border-radius: 6px;
}

.detail-input-edit {
	border: 1px rgba(0, 0, 0, 0.841);
	background-color: rgb(255, 157, 157);
	border-radius: 8px;
	text-align: center;
}
.stated {
	background-color: rgba(76, 88, 128, 0.154);
}
.calculated {
	background-color: rgb(250, 174, 174);
}
.manual {
	background-color: rgba(76, 88, 128, 0.154);
}
/* .final {
	background-color: rgba(4, 37, 154, 0.154);
} */
.cell-data {
	font-weight: 300;
	font-size: small;
	font-style: italic;
}
.cell-data_edit {
	font-weight: 300;
	font-size: small;
	font-style: italic;
	margin-left: 50%;
	transform: translate(-50%, 0%);
}
.cell-edit_icon {
	margin-right: 10px;
	font-size: smaller;
	color: rgb(71, 64, 64);
	cursor: pointer;
}
.cell-edit_icon:hover {
	color: black;
}
.error-detail-input {
	display: flex;
	justify-content: center;
	cursor: pointer;
}
.error-detail-input:hover {
	color: black;
}
.error-input-icons {
	margin-left: 5px;
	color: rgb(71, 64, 64);
}
.cell-name {
	font-weight: 500;
}

.category-box {
	margin-bottom: 3%;
}
.shipment-card-title {
	font-weight: 500;
}
.shipment-card-data {
	font-style: italic;
	/* background-color: #2a40384e; */
	font-size: small;
}
.error-shipment-row {
	display: flex;
	background-color: rgb(148 153 209 / 16%);
	border-bottom: 1px solid black;
}
.error-detail-warper {
	display: flex;
	justify-content: space-between;
}
.dhl-dispute-button {
	cursor: pointer;
	background-color: rgb(255 150 150);
	border-radius: 8px;
	display: flex;
	align-items: center;
	padding: 0px 9px;
	height: 50%;
}
.dhl-dispute-button:hover {
	background-color: #f03d3d;
}
.error-dhl-dropdown {
	width: 70%;
}
.quickbooks_page_warper {
}
.token_upload_warper {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.token_upload_warper textarea {
	width: 50%;
	border: 1px solid rgb(121, 119, 117);
	background-color: rgba(230, 220, 202, 0.591);
}
.quickbooks-section-warper {
	margin-top: 2vh;
	display: flex;
	flex-direction: row;
}
.create_invoices_section {
	flex-basis: 58%;
	padding: 0px 10px;
	box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
	min-height: 50vh;
	margin: 0 2vh;
}
.user_info_section {
	padding: 0px 10px;
	flex-basis: 38%;
	box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
	min-height: 50vh;
	margin-right: 2vh;
}
.invoice_list_q {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
}
.invoice_card_q {
	flex-basis: 29%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	background-color: gainsboro;
	margin: 3px;
}
.weeksInvoicesWarper {
	width: 97%;
	margin-left: 1%;
	padding-bottom: 1%;
}
.weeksSummery {
	background-color: #ffe8e8;
	margin: 5px 0px;
	display: flex;
	justify-content: center;
	justify-content: space-around;
	border-radius: 8px;
}
.weeksSummeryInfo {
	font-size: x-large;
}
/* Shipment Detail Page */
.shipment-detail-page {
	margin-top: 8vh;
	display: flex;
	text-align: center;
}
.search-section {
	flex-basis: 20%;
	background-color: lightgray;
	border-right: 1px solid rgb(200, 200, 200);
	max-height: 87.6vh;
	overflow: auto;
	overflow-x: hidden;
}
.search-result-data {
	flex-basis: 80%;
	overflow: auto;
	width: fit-content;
	max-height: 87.6vh;
}
.dispute-list-details-warper {
	background-color: #ffe8e85c;
	margin-top: 10px;
	padding: 5px;
}
.dispute-list_details {
	background-color: #c5bdcb7a;
	margin-bottom: 5px;
	border-radius: 5px;
}
.dispute_detail_name {
	font-weight: bold;
}
.dispute_detail {
	font-style: italic;
}
