.header {
	background-color: rgb(255, 232, 232);
	height: 8vh;
	min-height: 45px;
	width: 100%;
	transform-origin: top;
	transition: all 0.2s ease-in-out;
	position: fixed;
	z-index: 100;
}
.nav-bar {
	display: flex;
	justify-content: space-between;
}
.header-nav {
	margin-right: 3%;
	/* float: right;
	padding: 1.5vh 60px 0 0; */
}
.header-nav > li {
	display: inline-block;
	margin-left: 1em;
}
.header-nav a {
	color: #000;
	font-size: 0.8rem;
	font-weight: bold;
	text-decoration: none;
	text-transform: uppercase;
}
.nav-link-glow a {
	color: #ed6e2f;
}
.header-logo {
	background-color: rgb(255, 207, 207);
	box-sizing: border-box;
	display: block;
	height: 8vh;
	min-height: 45px;
	min-width: 65px;
	padding-top: 13px;
	position: relative;
	text-align: center;
	width: 20vh;
}
@media screen and (max-width: 850px) {
	.header-logo {
		display: none;
	}
}

.header-logo img {
	width: 17vh;
	min-width: 55px;
}
.header-nav a:hover,
.header-nav a:active {
	color: #ed6e2f;
}
.header-logo:hover,
.header-logo:active {
	opacity: 0.5;
}
.header-nav > li ~ li {
	border-left: 1px solid rgba(0, 0, 0, 0.3);
	padding-left: 1em;
}
.header-nav > li:last-child {
	cursor: pointer;
}
.header-nav > li:last-child:hover {
	color: #000;
}

ul li {
	list-style: none;
	display: inline-block;
	position: relative;
	text-decoration: none;
	text-align: center;
	font-family: arvo;
}

ul li ul {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	padding-left: 0;
	left: 5px;
	display: none;
	background-color: rgb(255, 207, 207);
	padding: 0 15px;
	z-index: 101;
}
ul li:hover > ul,
ul li ul:hover {
	visibility: visible;
	opacity: 1;
	display: block;
	min-width: 150px;
	text-align: left;
	padding-top: 20px;
	box-shadow: 0px 3px 5px -1px #ccc;
	z-index: 101;
}
ul li ul li {
	clear: both;
	width: 100%;
	text-align: left;
	margin-bottom: 20px;
	border-style: none;
	cursor: pointer;
}
ul li ul li a:hover {
	padding-left: 10px;
	border-left: 2px solid #3ca0e7;
	transition: all 0.3s ease;
	z-index: 101;
}
ul {
	padding: initial;
}
ul li ul li a {
	transition: all 0.5s ease;
}
.disable-parent-drop-down_link {
	pointer-events: none;
}

.back-button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem 1rem;
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	font-size: 18px;
	text-align: center;
	text-decoration: none;
	color: rgba(255, 255, 255, 0.455);
	backface-visibility: hidden;
	border: 0.3rem solid transparent;
	border-radius: 3rem;
	margin-left: 5%;
	margin-top: 0.5%;
	position: fixed;
	z-index: 101;
}

.backButton {
	border-color: transparent;
	background-color: #fff;
	opacity: 0.5;
	color: #000;
	transition: transform 0.2s cubic-bezier(0.235, 0, 0.05, 0.95);
}

.backButton:hover {
	transform: perspective(1px) scale3d(1.044, 1.044, 1) translateZ(0) !important;
	opacity: 1;
}
.back-button-container {
	width: 98vw;
	height: 50px;
	margin-top: 7%;
}
.fas.fa-arrow-left {
	margin-right: 5px;
}

.errorFallback-div {
	border: 2px solid black;
	margin: 1% 10% 1% 10%;
	padding: 1% 0%;
	text-align: center;
}

.deactivate-button {
	pointer-events: none;
	background-color: #e8ecee;
	color: rgba(0, 0, 0, 0.288);
}
.table-header {
	text-decoration: underline;
}

/* zenventory styles */
.zenventory-page {
	margin-top: 6%;
}
