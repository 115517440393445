.dhl-quoter-warper {
	background-color: #2d3748aa;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 5%;
	padding-bottom: 5%;
	border-radius: 8px;
}
.dhl-quoter-warper h2 {
	color: white;
}
/* styling for the upload file component */

.file-upload-warper {
	display: flex;
	justify-content: center;
	/* position: relative; */
}
.file-upload {
	flex-basis: 35%;
	visibility: visible;
	/* position: absolute; */
	/* left: 50%; */
	/* transform: translate(-50%, 0); */
	padding: 10px 10px;
	background: var(--gray-800);
	color: white;
	border-radius: 9px;
	font: 16px;
	box-shadow: 0 0 3px rgba(56, 54, 54, 0.86);
	margin-top: 2%;
}
.file-upload-warper input[type='file'] {
	display: none;
}

.osm-quoter-warper {
	background-color: #2d3748aa;
	margin-top: 5%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 5%;
	padding-bottom: 5%;
	border-radius: 8px;
}
.osm-quoter-warper h2 {
	color: white;
}
.osm-manifest-upload {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.osm-upload {
	width: 35%;
}
.osm-rate-card-upload {
	margin-top: 2%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
