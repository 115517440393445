.html {
	scroll-behavior: smooth;
}
.body {
	background: rgba(0, 128, 0, 0.3); /* Green background with 30% opacity */
}

.order_info {
	background-color: #ebecf0;
	width: 45%;
	flex-basis: 35%;
	margin-left: 4%;
	margin-bottom: 1%;
}
.edit-icons {
	float: right;
	margin-right: 10px;
	margin-top: 5px;
	font-size: 0.7em;
	visibility: hidden;
	cursor: pointer;
}
.info-actions {
	display: flex;
	margin-bottom: 10%;
	margin: auto;
	margin-top: 10%;
	align-items: center;
	flex-direction: column;
}
.invoice-actions {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	margin-left: 4%;
	margin-right: 4%;
	border: thin solid #ccc;
	border-radius: 16px;
	padding: 0.6em;
	width: 95%;
}
.invoice_cards {
	background-color: white;
	border: none;
	flex-basis: 25%;
	margin: 1%;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	text-align: center;
	cursor: pointer;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.invoice_cards_new {
	background-color: white;
	border-radius: 6px;
	border: 2px solid lightblue;
	flex-basis: 20%;
	margin: 1%;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.container__ribbon {
	position: absolute;
	right: -5px;
	top: -5px;
	z-index: 1;
	overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;
}
.container__ribbon span {
	font-size: 10px;
	font-weight: bold;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 20px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	width: 100px;
	display: block;
	background: #36a511;
	background: linear-gradient(#20c90d 0%, #1aa70a 100%);
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	position: absolute;
	top: 19px;
	right: -21px;
}
.container__ribbon span::before {
	content: '';
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #79a70a;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #79a70a;
}
.container__ribbon span::after {
	content: '';
	position: absolute;
	right: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid #79a70a;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #79a70a;
}

.invoiceGeneratorButtons {
	margin: 3% 3%;
	width: 90%;
	background-color: rgb(248, 250, 254);
	border: 2px solid rgb(43, 113, 137);
	border-radius: 10px;
	color: rgb(0, 0, 0);
	padding: 16px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	transition-duration: 0.4s;
	cursor: pointer;
}

.invoiceGeneratorButtons:not(:disabled):hover {
	border: 2px solid rgb(43, 113, 137);
	background-color: rgb(226, 248, 255);
}
.invoiceGeneratorButtons:active {
	background-color: rgb(167, 210, 223);
}
.check-box-container {
	font-style: oblique;
	font-size: small;
	font-weight: 300;
	display: block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

button:disabled {
	opacity: 0.65;
	cursor: not-allowed;
}

/* Hide the browser's default checkbox */
.check-box-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 4px;
	left: 0;
	height: 20px;
	width: 20px;
	border-radius: 99px;
	background-color: #eee;
}

/* On mouse-over, add a grey background color */
.check-box-container:hover input ~ .checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.check-box-container input:checked ~ .checkmark {
	background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.check-box-container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.check-box-container .checkmark:after {
	left: 7px;
	top: 3px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.processedCount {
	font-style: oblique;
	font-size: small;
	font-weight: 400;
}

@keyframes ani-float {
	0% {
		transform: translate(0, 0px);
	}
	50% {
		transform: translate(0, 6px);
	}
	100% {
		transform: translate(0, -0px);
	}
}
@keyframes ani-float-reverse {
	0% {
		transform: translate(0, -0px);
	}
	50% {
		transform: translate(0, -6px);
	}
	100% {
		transform: translate(0, 0px);
	}
}

.order_info_data {
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 1px 0 #091e4240;
	display: block;
	margin: 2.5% 3%;
	border-radius: 1px;
	width: -webkit-fill-available;
	font-weight: bold;
}

.order_info_data_values {
	font-weight: 400;
}
.order_info_data.info-input {
	border: none;
}
.order_info_date.edit-enabled {
	cursor: pointer;
}
.order_info_data.edit-enabled:hover {
	background-color: rgb(247, 247, 247);
}
.order_info_data.edit-enabled:hover .edit-icons {
	visibility: visible;
}
.edit-icons:hover {
	text-shadow: 0 0 3px dodgerBlue;
}

.invoice_cards:hover {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 10px 0 dodgerBlue;
}

input[type='number']:focus {
	border-color: dodgerBlue;
	box-shadow: 0 0 8px 0 dodgerBlue;
}

/* Remove number arrow on input felid */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.inputWithIcon {
	position: relative;
}

.inputWithIcon i {
	position: absolute;
	left: 87%;
	top: -4px;
	padding: 9px 8px;
	color: #aaa;
	transition: 0.2s;
}
.inputWithIcon i:hover {
	color: black;
}

.inputWithIcon i.check-icon {
	left: 78%;
}

/* TABLE STYLE */
.invoice_table {
	margin: 4% 1% 4% 1%;
	border: rgb(0, 0, 0);
	width: 98%;
}

.styled-table {
	border-collapse: collapse;
	margin: 4% auto;
	font-size: 0.7em;
	font-family: sans-serif;
	min-width: 400px;
	margin: 5px;
	/* border-right: 2px solid #009879; */
	/* border-left: 2px solid #009879; */
	/* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
}
.styled-table.shipping-service {
	/* width: 80%; */
	font-size: 0.8em;
}
.styled-table td {
	max-width: 250px;
}
.styled-table thead tr {
	/* background-color: #009879; */
	color: #ffffff;
	text-align: left;
	position: sticky;
	position: -webkit-sticky;
	top: 0px;
	z-index: 2;
	margin-top: 5%;
}

.styled-table th,
.styled-table td {
	padding: 8px 6px;
}
.styled-table tbody tr {
	border-bottom: thin solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
	background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
	border-bottom: 2px solid #45cbb0;
	margin-bottom: 4em;
}

.styled-table tbody tr.active-row a:link {
	color: #63a3f0;
}
.styled-table tbody tr.active-row a:visited {
	color: #0e5e4e;
}
.styled-table tbody tr.active-row a:hover {
	color: #009879;
}
.styled-table tbody tr.active-row:hover {
	/* font-weight: bold; */
	/* color: #009879; */
}
.tiers-list .active-row:hover {
	color: #0e5e4e;
	background-color: #d7e4f5;
	cursor: pointer;
}
.shipping-service .active-row:hover {
	color: #0e5e4e;
	background-color: #d7e4f5;
	cursor: pointer;
}
.editable-field input {
	border: none;
	background-color: rgb(232, 247, 227);
}
.loading-icon {
	animation: spin 2s linear infinite;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.button_unprocessed-fields {
	margin: 3% 3%;
	width: 70%;
	background-color: rgb(255, 247, 247);
	border: 2px solid rgb(210, 59, 42);
	border-radius: 10px;
	color: rgb(0, 0, 0);
	padding: 1px 2px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	transition-duration: 0.4s;
	cursor: pointer;
}
.button_unprocessed-fields:hover {
	background-color: rgb(237, 138, 138);
	border: 2px solid rgb(255, 84, 65);
	text-decoration: none;
	color: black;
}

.table-hider {
	height: 36px;
	width: 36px;
	border-radius: 99px;
	margin: auto;
	cursor: pointer;
	padding-right: 9px;
	padding-top: 7px;
}
.table-hider:hover {
	box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.fa-times-circle {
	display: inline-block;
	box-sizing: border-box;
	padding-top: 0.7em;
	margin-left: 9.5px;
}
.icon-in-cereal {
	padding-left: 2px;
}
.loading-icon.large {
	margin-left: 49%;
	font-size: 40px;
	margin-top: 5%;
	margin-bottom: 5%;
}
.table-hider .fa-times::before {
	color: black;
	font-size: 1.5em;
	margin: 0 auto;
	display: table-cell;
	vertical-align: middle;
}
.carrier-page_container {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.invoice-actions_container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 1%;
}

.dhl-invoice-list {
	text-align: center;
	margin-bottom: 3%;
}
.shipping-services-page-container {
	display: flex;
	flex-direction: column;
	margin-top: 6vh;
}
.shipping-services-page-container h1 {
	text-align: center;
	margin-bottom: -2%;
}

/* missing invoice process info/errors */

.invoice_card_with_errors_container {
	background-color: rgb(255, 255, 255);
	border-radius: 6px;
	border: 2px solid lightblue;
	flex-basis: 94%;
	margin: 1%;
	text-align: center;
}

.invoice_cards_with_errors {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
}
.process_invoice_card_button:hover {
	background-color: rgb(226, 248, 255);
}
.process_invoice_card_button {
	margin: 1% 3%;
	background-color: rgb(248, 250, 254);
	border: 2px solid rgb(43, 113, 137);
	border-radius: 10px;
	color: rgb(0, 0, 0);
	padding: 16px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	transition-duration: 0.2s;
	cursor: pointer;
	min-width: 35%;
}
.processed_and_checkbox {
}
.missing-data_accordion_container {
	flex-basis: 100%;
	display: flex;
	flex-direction: column;
	width: 100%;
}
.missing-data_accordion {
	/* overflow: hidden; */
	max-height: 1000px;
	transition: max-height 1s ease-in-out;
}
.missing-data_accordion[aria-expanded='true'] {
	max-height: 0px;
	transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
.missing-data-cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.missing-tiers {
	flex-basis: 48%;
	text-align: center;
	border: 2px solid lightblue;
	margin: 5px;
	padding-bottom: 1%;
}
.missing-tiers_list {
	display: flex;
	justify-content: space-around;
}
.missing-tiers_list-domestic {
	flex-basis: 49%;
	border-right: 1px solid black;
	max-height: 14em;
	overflow: scroll;
}
.missing-tiers_list-international {
	flex-basis: 49%;
	max-height: 14em;
	overflow: scroll;
}
.missing-customers-list {
	text-align: center;
	list-style-type: none;
	margin: 0;
	padding: 0;
}
.missing-customers-list-item {
	display: block;
	margin: 2% 4%;
	background-color: var(--blue-200);
	box-shadow: 0 5px 7px -1px rgb(51 51 51 / 23%);
	cursor: pointer;
	border-radius: 4px;
	transition: transform 0.15s cubic-bezier(0.7, 0.98, 0.86, 0.98),
		box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
}

.missing-customers-list-item:hover {
	transform: scale(1.1);
	box-shadow: 0 9px 47px 11px rgba(51, 51, 51, 0.18);
}
.missing-tiers h2 {
	font-weight: bold;
	display: inline-block;
	border-bottom: 1px dotted black;
}
.missing-tiers h4 {
	font-weight: bold;
	font-style: italic;
}
.missing-data-table {
	flex-direction: column;
	justify-content: space-evenly;
	max-height: 14em;
	min-height: 10em;
	overflow-x: hidden;
}
.missing-data-header {
	font-weight: 700;
	flex-basis: 100%;
	font-family: monospace;
	display: flex;
	justify-content: space-evenly;
	background-color: var(--gray-400);
	border-radius: 4px;
	margin: 1% 4%;
}
.missing-data_row {
	margin: 0.5% 4%;
	display: flex;
	justify-content: space-evenly;
	background-color: var(--blue-200);
	font-family: monospace;
	box-shadow: 0 5px 7px -1px rgb(51 51 51 / 23%);
	border-top: 1px solid black;
	align-items: center;
}
.missing-data-header-names {
	flex-basis: 33.3%;
}
.missing-data_row_data {
	flex-basis: 33.3%;
}
.missing-data-header-names.missing-data-small-basis {
	flex-basis: 25%;
}
.missing-data-header-names.missing-data-big-basis {
	flex-basis: 50%;
}
.missing-data_row_data.missing-data-small-basis {
	flex-basis: 25%;
	border-right: 1px solid black;
}
.missing-data_row_data.missing-data-big-basis {
	flex-basis: 50%;
}
.button-icon:hover {
	color: var(--blue-400);
	cursor: pointer;
}
.missing-data-header-names.custom-small {
	flex-basis: 20%;
}
.missing-data-header-names.custom-large {
	flex-basis: 60%;
}
.missing-data_row_data.custom-small {
	flex-basis: 20%;
	flex-wrap: nowrap;
}
.missing-data_row_data.custom-large {
	flex-basis: 60%;
}
.missing-data-row_tooltip {
	position: relative;
}
.missing-data-row_tooltip .missing-data-row_tooltip-text {
	visibility: hidden;

	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 10px;
	bottom: 125%;
	position: absolute;
}
.missing-data-row_tooltip:hover .missing-data-row_tooltip-text {
	visibility: visible;
}

.missing-data-row_tooltip .missing-data-row_tooltip-text::after {
	content: ' ';
	position: absolute;
	top: 100%; /* At the bottom of the tooltip */
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}
.add-service-button {
	margin: 2% auto -1%;
}
.text-input-like-input {
	display: flex;
	color: wheat;
	letter-spacing: 1px;
	width: 100%;
}

/************* DHL SHIPMENTS PROCESS *************/

.shipments-process-wrapper {
	text-align: center;
}
.process-stage_dhl {
	width: 70%;
	margin: 0% auto 1%;
	display: flex;
	justify-content: space-around;
	align-items: baseline;
	font-weight: 500;
	background-color: var(--gray-400);
	padding: 5px 8px 5px 8px;
	font-size: large;
	border-radius: 3px;
}
.process-stage-info {
	margin-left: 0.3em;
	font-style: italic;
	font-weight: 600;
}
.process-stage-button {
	border: 1px solid var(--gray-600);
	border-radius: 5px;
	text-align: center;
	cursor: pointer;
	border-radius: 10px;
	padding: 0px 8px;
	flex-basis: 10%;
}
.process-stage-button:hover {
	background-color: var(--gray-200);
}
.stage-content {
	flex-basis: 40%;
}

/* Invoice Chart */

.invoice-chart {
	margin-left: 5%;
	margin-top: 1%;
	margin-right: 5%;
	margin-bottom: 2%;
	width: 90%;
}
.react-resizable {
	max-width: 100%;
	/* overflow-x: scroll; */
}

/* Shipments Search */

:root {
	--rad: 0.7rem;
	--dur: 0.3s;
	--color-dark: #2f2f2f;
	--color-light: #fff;
	--color-brand: #57bd84;
	--font-fam: 'Lato', sans-serif;
	--height: 5rem;
	--btn-width: 6rem;
	--bez: cubic-bezier(0, 0, 0.43, 1.49);
}

.shipment-search {
	display: flex;
	justify-content: center;
}
.searchForm {
	margin-top: 10%;
	position: relative;
	width: 90%;
	background: var(--color-brand);
	border-radius: var(--rad);
}

.searchFormInput,
.searchFormButton {
	height: var(--height);
	font-family: var(--font-fam);
	border: 0;
	color: var(--color-dark);
	font-size: 1.8rem;
}

.searchForm input[type='search'] {
	outline: 0;
	width: 100%;
	background-color: rgb(255, 232, 232);
	padding: 0 1.6rem;
	border-radius: var(--rad);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	transition: all var(--dur) var(--bez);
	transition-property: width, border-radius;
	position: relative;
}

.searchFormButton {
	display: none;
	position: absolute;
	margin-top: 0;
	top: 0;
	right: 0;
	width: var(--btn-width);
	font-weight: bold;
	background: var(--color-brand);
	border-radius: 0 var(--rad) var(--rad) 0;
}

.searchForm input:not(:-moz-placeholder-shown) {
	border-radius: var(--rad) 0 0 var(--rad);
	width: calc(100% - var(--btn-width));
}

.searchForm input:not(:-ms-input-placeholder) {
	border-radius: var(--rad) 0 0 var(--rad);
	width: calc(100% - var(--btn-width));
}

.searchForm input:not(:placeholder-shown) {
	margin-right: 50%;
	border-radius: var(--rad) 0 0 var(--red);
	width: calc(100% - var(--btn-width));
}
.searchForm input:not(:-moz-placeholder-shown) + button {
	display: block;
}
.searchForm input:not(:-ms-input-placeholder) + button {
	display: block;
}
.searchForm input:not(:placeholder-shown) + button {
	display: block;
}

.searchFormLabel {
	position: absolute;
	clip: rect(1px, 1px, 1px, 1px);
	padding: 0;
	border: 0;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

/* SEARCH DROP DOWN */

/* Please ❤ this if you like it! */

.sec-center {
	position: relative;
	max-width: 100%;
	text-align: center;
	/* z-index: 200; */
	margin-top: 5%;
}
.sec-center [type='checkbox']:checked,
.sec-center [type='checkbox']:not(:checked) {
	position: absolute;
	left: -9999px;
	opacity: 0;
	pointer-events: none;
}

.dropdown:checked + label,
.dropdown:not(:checked) + label {
	position: relative;
	font-family: 'Roboto', sans-serif;
	font-weight: 560;
	font-size: 15px;
	line-height: 2;
	transition: all 200ms linear;
	border-radius: 0.7rem 0px 0px 0.7rem;
	width: 220px;
	letter-spacing: 1px;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: flex;
	flex-direction: column;
	-webkit-align-items: center;
	-moz-align-items: center;
	-ms-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
	justify-content: center;
	-ms-flex-pack: center;
	text-align: center;
	border: none;
	background-color: #ffcfcf;
	cursor: pointer;
	color: #102770;
	height: 100%;
	box-shadow: 0 12px 35px 0 rgb(165 155 120 / 15%);
}
.dropdown-choice-text {
	width: inherit;
	font-size: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0px 5px;
}
.dropdown:checked + label:before,
.dropdown:not(:checked) + label:before {
	position: fixed;
	top: 0;
	left: 0;
	content: '';
	width: 100%;
	height: 100%;
	z-index: -1;
	cursor: auto;
	pointer-events: none;
}
.dropdown:checked + label:before {
	pointer-events: auto;
}
.uil {
	font-size: 10px;
}
.dropdown:not(:checked) + label .uil {
	font-size: 1em;
	margin-left: 10px;
	transition: transform 100ms linear;
}
.dropdown:checked + label .uil {
	transform: rotate(180deg);
	font-size: 1em;
	margin-left: 10px;
	transition: transform 200ms linear;
}
.section-dropdown {
	position: absolute;
	padding: 5px;
	background-color: #111;
	/* top: 70px; */
	left: 0;
	width: 100%;
	border-radius: 4px;
	display: block;
	box-shadow: 0 14px 35px 0 rgba(9, 9, 12, 0.4);
	z-index: 2;
	opacity: 0;
	pointer-events: none;
	transform: translateY(20px);
	transition: all 200ms linear;
	width: fit-content;
}

.dropdown:checked ~ .section-dropdown {
	opacity: 1;
	pointer-events: auto;
	transform: translateY(0);
}
.section-dropdown:before {
	position: absolute;
	top: -20px;
	left: 0;
	width: 100%;
	height: 20px;
	content: '';
	display: block;
	z-index: 1;
}
.section-dropdown:after {
	position: absolute;
	top: -7px;
	left: 30px;
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid #111;
	content: '';
	display: block;
	z-index: 2;
	transition: all 200ms linear;
}

.sec-center a {
	position: relative;
	color: #fff;
	transition: all 200ms linear;
	font-family: 'Roboto', sans-serif;
	font-weight: 540;
	font-size: 15px;
	border-radius: 2px;
	padding: 5px 0;
	padding-left: 20px;
	padding-right: 15px;
	margin: 2px 0;
	text-align: left;
	text-decoration: none;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-moz-align-items: center;
	-ms-align-items: center;
	align-items: center;
	justify-content: space-between;
	-ms-flex-pack: distribute;
}

.sec-center a:hover {
	color: #102770;
	background-color: #ffeba7;
}
.dark-light:checked ~ .sec-center .section-dropdown a:hover {
	color: #ffeba7;
	background-color: #102770;
}
.sec-center a .uil {
	font-size: 22px;
}
.dropdown-sub:checked + label,
.dropdown-sub:not(:checked) + label {
	position: relative;
	color: #fff;
	transition: all 200ms linear;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	font-size: 15px;
	border-radius: 2px;
	padding: 5px 0;
	padding-left: 20px;
	padding-right: 15px;
	text-align: left;
	text-decoration: none;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-moz-align-items: center;
	-ms-align-items: center;
	align-items: center;
	justify-content: space-between;
	-ms-flex-pack: distribute;
	cursor: pointer;
}
.dropdown-sub:checked + label .uil,
.dropdown-sub:not(:checked) + label .uil {
	font-size: 22px;
}
.dropdown-sub:not(:checked) + label .uil {
	transition: transform 200ms linear;
}
.dropdown-sub:checked + label .uil {
	transform: rotate(135deg);
	transition: transform 200ms linear;
}
.dropdown-sub:checked + label:hover,
.dropdown-sub:not(:checked) + label:hover {
	color: #102770;
	background-color: #ffcfcf;
}
.section-dropdown-sub {
	position: relative;
	display: block;
	width: 100%;
	pointer-events: none;
	opacity: 0;
	max-height: 0;
	padding-left: 10px;
	padding-right: 3px;
	overflow: hidden;
	transition: all 200ms linear;
}
.dropdown-sub:checked ~ .section-dropdown-sub {
	pointer-events: auto;
	opacity: 1;
	max-height: 999px;
}
.section-dropdown-sub a {
	font-size: 14px;
}
.section-dropdown-sub a .uil {
	font-size: 20px;
}

/* for look up data */

.custom-for-look-up-page {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	/* background-color: rgb(188 201 215); */
	background-color: var(--gray-400);
}
.search-result-header {
	display: flex;
	justify-content: space-around;
	margin-top: 1%;
	font-size: larger;
}

.profit-report-button {
	cursor: pointer;
	min-width: 15%;
}
.button-badge .button-badge-data {
	visibility: visible;
	background-color: #b9cce0ca;
	color: rgb(54, 54, 54);
	text-align: center;
	padding: 5px 10px;
	position: absolute;
	margin-top: -1.5%;
	border-radius: 50%;
}

/* DHL MENU BAR */
.dhl-menu-bar_container {
	display: flex;
	justify-content: center;
	position: sticky;
	/* transform-origin: top; */
	transition: all 0.2s ease-in-out;
	margin-bottom: 0.1px;
	border-bottom: 1px solid #2f2f2f;
	border-top: 1px solid #2f2f2f;
	background: #e8dada;
	z-index: 99;
	height: 4vh;
	margin-bottom: 8vh;
}

.dhl-menu-bar {
	flex-basis: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.dhl-menu-bar li a {
	cursor: pointer;
	font-weight: 700;
	padding-bottom: 12px;
	padding-top: 8px;
	white-space: nowrap;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: 0.25px;
	color: black;
}
.dhl-page-wrapper {
	text-align: center;
}
