/* The alert message box */
.alert {
	/* position: relative; */
	position: fixed;
	top: 9%;
	left: 50%;
	z-index: 900;
	width: 35%;
	padding: 1rem 1rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	transform: translateX(-50%);
}
.error {
	color: #842029;
	background-color: #f9bec3;
	border-color: #f7a0a9;
}

.success {
	color: #0f5132;
	background-color: #d1e7dd;
	border-color: #badbcc;
}

.warning {
	color: #664d03;
	background-color: #fff3cd;
	border-color: #ffecb5;
}
.primary {
	color: #084298;
	background-color: #cfe2ff;
	border-color: #b6d4fe;
}

.secondary {
	color: #41464b;
	background-color: #e2e3e5;
	border-color: #d3d6d8;
}

.info {
	color: #055160;
	background-color: #cff4fc;
	border-color: #b6effb;
}
/* The close button */
.closebtn {
	margin-left: 15px;
	color: white;
	font-weight: bold;
	float: right;
	font-size: 22px;
	line-height: 20px;
	cursor: pointer;
	transition: 0.3s;
}
.callout {
	margin-right: 2%;
}

/* When moving the mouse over the close button */
.closebtn:hover {
	color: black;
}
.hide {
	display: none;
}
