.rate-card-page {
	height: 85vh;
	display: flex;
	margin-top: 6%;
}
.rate-card-header {
	text-align: center;
	font-family: 'Libre Baskerville', serif;
}

:root {
	--gray-900: #1a202c;
	--gray-800: #2d3748;
	--gray-700: #4a5568;
	--gray-600: #718096;
	--gray-500: #a0aec0;
	--gray-400: #cbd5e0;
	--gray-300: #e2e8f0;
	--gray-200: #edf2f7;
	--gray-100: #f7fafc;
	--red-100: #fff5f5;
	--red-200: #fed7d7;
	--red-300: #feb2b2;
	--red-400: #fc8181;
	--red-500: #f56565;
	--red-600: #e53e3e;
	--red-700: #c53030;
	--red-800: #9b2c2c;
	--red-900: #742a2a;
	--blue-100: #ebf8ff;
	--blue-200: #bee3f8;
	--blue-300: #90cdf4;
	--blue-400: #63b3ed;
	--blue-500: #4299e1;
	--blue-600: #3182ce;
	--blue-700: #2b6cb0;
	--blue-800: #2c5282;
	--blue-900: #2a4365;
	--white: white;
	--red: #f56565;
	--pink1: rgb(255, 197, 197);
	--pink2: rgb(246, 196, 196);
	--pink3: rgb(254, 217, 217);
	--green: rgb(122, 216, 183);
	--text-decoration-color: var(--gray-400);
	--text-color: var(--gray-800);
	--focus-ring-color: var(--blue-500);
}
/* ACORDION RELATED CSS */

.carrier-accordions {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.accordion {
	flex-basis: 100%;
	margin-top: 5%;
	background-color: var(--red-200);
	margin: 1em;
	border-radius: 8px;
	width: 85%;
}

.accordion-title p {
	margin: 0;
}
.accordion-title {
	display: flex;
	cursor: pointer;
}
.start-date_accordion-title {
	margin-left: 10px;
}
.fa-sort-down:before {
	width: 16px;
	height: 16px;
	align-self: center;
	margin-left: auto;
	transition: all 0.35s ease;
}
.arrow-toggle {
	width: 16px;
	height: 16px;
	align-self: center;
	margin-left: auto;
	transform: rotate(90deg);
	transform-origin: center;
	transition: all 0.35s ease;
}
.arrow-toggle[aria-expanded='true'] {
	transform: rotate(1deg);
	transform-origin: center;
}
.accordion-content {
	overflow: hidden;
	max-height: 0px;
	transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
.accordion-content[aria-expanded='true'] {
	margin-top: 0.5em;
	overflow: visible;
	max-height: 1000px;
	transition: overflow max-height 1s ease-in-out;
	/* animate to overflow visible after 1 second */
}

/* @keyframes no-hidden {
    0%{
     overflow: hidden;
    }
  
    100%{
      overflow: visible;	
    }
  } */

.carrier-service {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid var(--gray-900);
	background-color: var(--blue-200);
	margin-top: 0.5%;
	padding: 5px;
	border-radius: 0px 0px 4px 4px;
}
.carrier-service.clicked_accordion-row {
	background-color: var(--blue-300);
}

.carrier-service-title {
	flex-basis: 80%;
	margin-left: 1em;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.carrier-tier-delete {
	margin-left: 12%;
	text-align: center;
	cursor: pointer;
	flex-basis: 10%;
	background-color: var(--red-400);
	padding: 5px;
	border-radius: 10px;
}
.teri-drop-down-select {
	border: none;
	border-radius: 5px;
	padding: 6px;
}
.carrier-tier-download {
	cursor: pointer;
	flex-basis: 10%;
	text-align: center;
	background-color: rgb(122, 216, 183);
	padding: 5px;
	border-radius: 10px;
	/* margin-left: 19%; */
}
.carrier-tier-upload {
	margin-right: 1em;
	cursor: pointer;
	padding: 5px;
	background-color: var(--blue-500);
	border-radius: 10px;
	flex-basis: 10%;
	text-align: center;
	margin-left: 1%;
}
.carrier-tier-add {
	margin-right: 1em;
	cursor: pointer;
	padding: 5px;
	background-color: var(--blue-400);
	border-radius: 10px;
	flex-basis: 10%;
	text-align: center;
	margin-left: 1%;
}
.carrier-tier-add:hover {
	background-color: var(--blue-600);
	/* transform: translateX(6px); */
}
.carrier-tier-add.clicked_accordion-add {
	background-color: var(--blue-600);
	transform: translateX(6px);
}

.add-term {
	text-align: center;
	margin-bottom: 1%;
}
.create-term_button {
	margin-top: 0%;
	margin-left: 1%;
	padding: 0.26em;
	border: 1.5px solid var(--blue-900);
	background-color: var(--gray-100);
}
.create-term_button:hover {
	color: var(--red-300);
}
.create-term_button:active {
	color: var(--red-500);
}

.term_date-picker {
	border: 1.5px solid var(--blue-900);
	background-color: var(--gray-100);
	border-radius: 8px;
}

.con-tooltip {
	position: relative;
	transition: all 0.3s ease-in-out;
}

.tooltip {
	visibility: hidden;
	z-index: 101;
	opacity: 1;
	min-width: 250px;
	max-width: 390px;
	padding: 10px 10px;
	background: var(--gray-800);
	color: white;
	position: absolute;
	top: -140%;
	left: -25%;

	border-radius: 9px;
	font: 16px;

	transform: translateY(9px);
	transition: all 0.3s ease-in-out;

	box-shadow: 0 0 3px rgba(56, 54, 54, 0.86);
}

.tooltip::after {
	content: ' ';
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 12px 12.5px 0 12.5px;
	border-color: #333 transparent transparent transparent;
	position: absolute;
	left: 10%;
}

/* .con-tooltip:hover .tooltip{
    visibility: visible;
    transform: translateY(-10px); 
    opacity: 1;
    transition: .3s linear;
    animation: odsoky 1s ease-in-out infinite  alternate; */

.tooltip.visible {
	visibility: visible;
}

@keyframes odsoky {
	0% {
		transform: translateY(6px);
	}

	100% {
		transform: translateY(1px);
	}
}

/*right*/

.right .tooltip {
	top: -20%;
	left: 115%;
}
.right:hover {
	/* transform: translateX(6px); */
}

.right .tooltip::after {
	top: 15%;
	left: -5%;
	transform: rotate(90deg);
}

.carrier-tier-add input[type='file'] {
	display: none;
}
.custom-file-upload {
	border: 1px solid #ccc;
	padding: 6px 12px;
	cursor: pointer;
}
.custom-file-upload:hover {
	background-color: var(--gray-900);
}
.custom-file-upload:active {
	background-color: var(--gray-700);
	color: var(--gray-800);
}
.upload-file {
	display: flex;
	width: 100%;
	justify-content: center;
}
.file-name {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	color: #1a202c;
	background-color: white;

	padding: 6px 12px;
}

.bn5 {
	padding: 0.6em 2em;
	border: none;
	outline: none;
	color: rgb(255, 255, 255);
	background: #111;
	cursor: pointer;
	position: relative;
	z-index: 0;
	border-radius: 10px;
	text-align: center;
	margin-top: 9px;
}

.bn5:before {
	content: '';
	background: linear-gradient(
		45deg,
		#bc0606,
		#086187,
		#1a7804,
		#509336,
		#00ffd5,
		#002bff,
		#7a00ff,
		#ff00c8,
		#ff0000
	);
	position: absolute;
	top: -2px;
	left: -2px;
	background-size: 400%;
	z-index: -1;
	filter: blur(5px);
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	animation: glowingbn5 20s linear infinite;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
	border-radius: 10px;
}

@keyframes glowingbn5 {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}

.bn5:active {
	font-weight: bold;
}

/* .bn5:active:after {
  background: transparent;
} */

.bn5:hover:before {
	opacity: 1;
}

.bn5:after {
	z-index: -1;
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background: #191919;
	left: 0;
	top: 0;
	border-radius: 10px;
}

.accordion-input {
	flex-basis: 50%;
	cursor: default;
}
.accordion-input i {
	position: absolute;
}
.create-term-form {
	border: 2.5px groove var(--blue-400);
	width: 30%;
	margin: auto;
	padding: 10px 0px;
	border-radius: 10px;
}
.close_update-term {
	position: absolute;
	margin-left: 10px;
}
.fas.fa-times {
	float: right;
	color: var(--gray-600);
}
.fas.fa-times:hover {
	color: var(--gray-900);
	cursor: pointer;
}
.accordion-start-date {
	margin-left: 10px;
}
.accordion-start-date-value {
	margin-left: 10px;
	font-weight: 600;
}

input.tier-input_term {
	width: 100px;
	border: none;
	margin-left: 8px;
	background-color: transparent;
	transition: width 0.4s ease-in-out;
}
input.tier-input_term:focus {
	background-color: var(--red-200);
	border-radius: 8px;
}
.update-tier-button {
	cursor: pointer;
	font-size: 0.4em;
	width: 20px;
	margin-left: 4px;
	color: var(--gray-600);
}
.update-tier-button:hover {
	color: var(--gray-700);
}
.submit-update-tier-button {
	cursor: pointer;
	font-size: 0.6em;
	color: var(--gray-700);
}
.fa-paper-plane {
	font-size: 0.7em;
	width: 20px;
	cursor: pointer;
	margin-left: 10px;
}
.paper-plane_animate {
	animation-name: slide-tr;
	animation-duration: 2.5s;
	animation-timing-function: ease;
	animation-delay: 0.2s;
	animation-iteration-count: 1;
	animation-direction: alternate;
	animation-fill-mode: forwards;
}
@keyframes slide-tr {
	0% {
		transform: rotate(0deg);
		transform-origin: 50% 50%;
		color: var(--red-600);
	}
	10% {
		transform: rotate(8deg);
	}
	20% {
		transform: rotate(-8deg);
	}
	30% {
		transform: rotate(10deg);
	}
	40% {
		transform: rotate(-8deg);
	}
	50% {
		transform: translateY(0) translateX(0);
		transform: rotate(0deg);
		transform-origin: 50% 50%;
	}
	100% {
		transform: translateY(-200px) translateX(200px);
		color: var(--blue-400);
	}
}
.rate-cards {
	flex-basis: 65%;
}
.customer-filter_rate-cards {
	flex-basis: 35%;
	text-align: center;
	display: flex;
}
.customer-filter-fieldset_rate-cards {
	width: 85%;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.filter-customer-using-tier {
	margin-top: 6%;
}

.tiers-list {
	display: flex;
	justify-content: space-evenly;
	align-items: baseline;
}
.success-text {
	color: #7dd698;
	font-style: italic;
}
.customers-using-rate-card-legend {
	display: flex;
}
.customer-invoice-list.customer-using-card {
	width: 100%;
	justify-content: center;
	max-height: 110em;
}
.card-headers.customer-using-card {
	width: 80%;
	justify-content: space-around;
}
.customer-name_customer-using-list {
	color: var(--blue-800);
}
.customer-invoice-card.list-data_customer-using-list {
	justify-content: space-around;
}
.customer-invoice-card .customer-name_customer-using-list {
}
table tbody tr.highlight.hasCustomers {
	background-color: #f77b7b3c;
}
.tier-list {
	margin-top: 6%;
}
